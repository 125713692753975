<template>
  <v-container class="px-2">
    <v-card flat class="mx-0 px-0 transparent">
      <template v-if="waitingForVerification">
        <v-card class="mx-0 px-0">
          <v-card-title>Email verification</v-card-title>
          <v-card-text class="px-0">
            We've sent you an verification code at the email address you've
            specified
            <span>({{ this.accountInfo.username }})</span>. Please enter the
            verification code below and click "Verify Email" to complete your
            email address change. Or click "Re-send Code" to receive another
            verification code.
          </v-card-text>
          <v-row>
            <v-col cols="6">
              <v-text-field
                label="Verification Code"
                v-model="vcode"
                ref="vcode"
                type="number"
                pattern="[0-9]*"
                inputmode="decimal"
                required
                prepend-icon="mdi-check-decagram"
                :counter="6"
              />
            </v-col>
          </v-row>
          <v-card-actions class="px-0">
            <v-spacer />
            <v-btn @click="resendCode" right color="primary" plain>
              Re-Send Code
            </v-btn>
            <v-btn @click="verifyEmail" right color="success" plain>
              Verify Email
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
      <template v-else>
        <v-card-text class="pa-0">
          To change your email address, modify the entry below and click "Change
          Email". You will then receive a verification code at your current
          address. Use the verification code to confirm the change. Please note
          the code will expire within 30 minutes.
        </v-card-text>
        <v-card-text class="px-0">
          <v-text-field
            :error-messages="emailErrors"
            label="Email"
            v-model="email"
            ref="email"
            type="email"
            required
            prepend-icon="mdi-email"
            :counter="250"
            @input="$v.email.$touch()"
            @blur="$v.email.$touch()"
          />
        </v-card-text>
        <v-card-actions class="pa-0">
          <v-spacer />
          <BaseActionButton
            class="charcoal paper--text"
            :large="!isPhone"
            v-bind="$attrs"
            :disabled="this.$v.$anyError"
            :label="'Change Email'"
            icon="mdi-email-edit-outline"
            @clickedThis="saveEmail"
          />
        </v-card-actions>
      </template>
    </v-card>
  </v-container>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import axios from 'axios'
import util from '@/mixins/util.js'

export default {
  mixins: [util],
  props: {
    accountInfo: Object
  },
  data: () => ({
    email: '',
    newEmail: '',
    waitingForVerification: false,
    vcode: ''
  }),
  mounted() {
    this.$refs.email.focus()
    this.email = this.accountInfo.username
    this.checkPendingChanges()
  },
  updated() {
    this.checkPendingChanges()
  },
  validations: {
    email: { required, email }
  },
  computed: {
    emailErrors() {
      const errors = []
      if (!this.$v.email.$dirty) return errors
      !this.$v.email.email && errors.push('Invalid e-mail')
      !this.$v.email.required && errors.push('E-mail is required')
      return errors
    }
  },
  methods: {
    checkPendingChanges() {
      const email = {
        email: this.accountInfo.email
      }
      return axios
        .put(
          this.$store.state.config.baseURL + '/users/email/haspendingchange',
          email
        )
        .then(response => {
          if (response.status == 200) {
            if (response.data.data['found'] != 'No') {
              this.waitingForVerification = true
              this.newEmail = response.data.data['found']
            } else this.waitingForVerification = false
          } else {
            this.waitingForVerification = false
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              {
                root: true
              }
            )
            return
          }
        })
    },
    saveEmail() {
      this.$v.$touch()
      if (this.$v.$anyError) {
        return
      } else {
        const email = {
          email: this.accountInfo.username,
          new_email: this.email,
          emailid: this.accountInfo.emailid
        }
        return axios
          .put(this.$store.state.config.baseURL + '/users/change/email', email)
          .then(response => {
            if (response.status == 200) {
              this.waitingForVerification = true
              this.$emit('updateInfo')
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    },

    verifyEmail() {
      this.$v.$touch()
      if (this.$v.$anyError) {
        return
      } else {
        const email = {
          email: this.accountInfo.username,
          new_email: this.email,
          token: this.vcode,
          emailid: this.accountInfo.emailid
        }
        return axios
          .put(this.baseURL + '/users/validate/email', email)
          .then(response => {
            if (response.status == 200) {
              this.$emit('updateInfo')
              this.email = this.newEmail
              this.vcode = ''
              this.waitingForVerification = false
              this.$store.dispatch(
                'notification/addNotification',
                'Verification code was valid. Email change complete.',
                2000,
                true,
                {
                  root: true
                }
              )
              this.waitingForVerification = true
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    },
    resendCode() {
      const email = {
        email: this.accountInfo.username
      }
      return axios
        .put(
          this.$store.state.config.baseURL + '/users/email/resendcode',
          email
        )
        .then(response => {
          if (response.status == 200) {
            this.$emit('updateInfo')
            this.waitingForVerification = true
            this.$store.dispatch(
              'notification/addNotification',
              'A new verification code was sent to your original email address. Check your email.',
              2000,
              true,
              {
                root: true
              }
            )
            this.waitingForVerification = true
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              {
                root: true
              }
            )
            return
          }
        })
    }
  }
}
</script>
